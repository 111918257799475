<template>
  <div>    
    <div class="welcome-text"> 
      Bem vindo(a), 
      <span class="text-capitalize"> 
        {{ userName }}.
      </span> 
      <br>
      <span v-if="isFreemium">
        Acesso grátis <b>{{ freemiumRemainingTime }}</b> dias restantes, <b style="cursor: pointer" @click="goToPreInterview">faça o seu diagnóstico gratuito aqui.</b>
      </span>
    </div>
    <NavMenu />
    <router-view />
  </div>
</template>
  
  <script>
  import { RouterView } from "vue-router";
  import NavMenu from "../../layout/CashFlow/NavMenu.vue";
  import { useAppStore } from "@/store/store.js";
  export default {
    components: { NavMenu },
    data() {
        return {
            cashFlow: 1000,
        };
    },
    computed: {
      userName() {
        const store = useAppStore();
        const user = store.userResponse.data;
        if (user) {
          return user.userFastapp?.name || 'Usuário';
        }
        return 'Usuário'
      },
      isFreemium() {
        const store = useAppStore();
        return store.hasFreemiumAccess;
      },
      freemiumRemainingTime() {
        const store = useAppStore();
        return store.freemiumRemainingTime;
      }
    },
    methods: {
        increaseCashFlow() {
            this.cashFlow += 100;
        },
        async goToPreInterview(route) {
          const store = useAppStore();
          await store.goToPreInterview();
        },
    }
};
  </script>
<style scoped>
  .welcome-text {
    font-size: small;
    color: white;
  }
  b {
    color: #cb5540  
  }
</style>