<template>
  <Toast />
  <div class="loading-container">
    <img class="loading-logo" src="~@/assets/LOGOCOMPLETO.png" alt="Loading" />
  </div>
  <div class="content main-login d-flex align-items-center">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <div class="left-login col-lg-9">
            <div class="card-login">
              <img
                class="logo"
                src="~@/assets/LOGOCOMPLETO.png"
                alt="AdminLTELogo"
              />
              <h3>Crie sua conta agora, é rapidinho!</h3>

              <div class="textfield mt-3" v-if="fieldCpf">
                <label for="cpf">CPF</label>
                <input
                  id="cpf"
                  v-model="formData.cpf"
                  type="text"
                  class="form-control"
                  placeholder="Informe seu CPF, apenas números"
                  required
                  @input="formatCPF"
                />
              </div>

              <div class="textfield mt-3" v-if="fieldCellPhone">
                <label for="nome">Celular</label>
                <vue-tel-input
                  id="cellPhone"
                  v-model="formData.cell_phone"
                  class="form-select"
                  style="background-color: #fff"
                />
              </div>

              <div class="textfield mt-3" v-if="fieldEmail">
                <label for="email">E-mail</label>
                <input
                  id="email"
                  v-model="formData.email"
                  type="email"
                  class="form-control"
                  placeholder="Informe seu e-mail"
                  required
                />
              </div>

              <div class="textfield mt-3" v-if="fieldName">
                <label for="nome">Nome</label>
                <input
                  id="nome"
                  v-model="formData.name"
                  type="text"
                  class="form-control cell_phone"
                  placeholder="Informe seu nome completo"
                  required
                />
              </div>

              <div class="textfield mt-3" v-if="fieldPassword">
                <label for="senha">Senha</label>
                <input
                  id="senha"
                  v-model="formData.password"
                  type="password"
                  class="form-control"
                  placeholder="Informe sua senha com no mínimo 8 caracteres"
                  required
                />
              </div>
              <div class="textfield mt-3" v-if="fieldPasswordComfirmation">
                <label for="confirmaSenha">Confirmação de senha</label>
                <input
                  id="confirmaSenha"
                  v-model="formData.confirmPassword"
                  type="password"
                  class="form-control"
                  placeholder="Confirme sua senha"
                  required
                />
              </div>

              <input type="hidden" v-model="customer_id" />
              <input type="hidden" v-model="token" />

              <button
                type="button"
                :disabled="loading"
                class="btn-login btn-block"
                @click="registerUser"
              >
                {{ loading ? "Aguarde..." : "Registrar" }}
              </button>

              <div class="mt-3">
                <router-link to="/login" class="cadastro"
                  >Já possuo cadastro</router-link
                >
              </div>

              <div v-if="displayError.status" id="responseMessage" class="mt-3">
                <div class="alert alert-danger">
                  {{ displayError.message }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-12" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      loading: false,
      formData: {
        cpf: "",
        name: "",
        email: "",
        password: "",
        confirmPassword: "",
        cell_phone: "",
        customer_id: "",
        token: "",
      },
      displayError: { status: false, message: "" },
      fieldCpf: 1,
      fieldEmail: 0,
      fieldCellPhone: 0,
      fieldName: 0,
      fieldPassword: 0,
      fieldPasswordComfirmation: 0,
    };
  },
  created() {
    setTimeout(() => {
      const loadingContainer = document.querySelector(".loading-container");
      const content = document.querySelector(".content");

      loadingContainer.style.opacity = "0";
      loadingContainer.style.pointerEvents = "none";
      content.style.display = "block";
      this.loadingDone = true; // Set a flag to indicate loading is done
    }, 2000);
  },
  methods: {
    formatCPF() {
      let cpf = this.formData.cpf.replace(/\D/g, "");
      cpf = cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
      this.formData.cpf = cpf;
    },
    async registerUser() {
      const {
        cpf,
        name,
        email,
        password,
        confirmPassword,
        cell_phone,
        customer_id,
        token,
      } = this.formData;

      if (this.fieldCpf == 1) {
        if (cpf == "") {
          this.$toast.add({
            severity: "error",
            summary: "Campo invalido",
            detail: "Informe o seu CPF.",
            life: 5000,
          });
          return;
        }
      }

      if (this.fieldCellPhone == 1) {
        if (cell_phone == "") {
          this.$toast.add({
            severity: "error",
            summary: "Campo invalido",
            detail: "Informe o seu Telefone.",
            life: 5000,
          });
          return;
        }
      }

      if (this.fieldEmail == 1) {
        if (email == "") {
          this.$toast.add({
            severity: "error",
            summary: "Campo invalido",
            detail: "Informe o seu Email.",
            life: 5000,
          });
          return;
        }
      }

      if (this.fieldName == 1) {
        if (name == "") {
          this.$toast.add({
            severity: "error",
            summary: "Campo invalido",
            detail: "Informe o seu nome.",
            life: 5000,
          });
          return;
        }
      }

      if (this.fieldPassword == 1) {
        if (password == "") {
          this.$toast.add({
            severity: "error",
            summary: "Campo invalido",
            detail: "Informe a senha.",
            life: 5000,
          });
          return;
        }
      }
      if (this.fieldPasswordComfirmation == 1) {
        if (confirmPassword == "") {
          this.$toast.add({
            severity: "error",
            summary: "Campo invalido",
            detail: "Informe a confitmação de senha.",
            life: 5000,
          });
          return;
        }
      }

      if (
        this.fieldPassword == 1 &&
        this.fieldPasswordComfirmation == 1 &&
        password != "" &&
        confirmPassword != ""
      ) {
        if (password != confirmPassword) {
          this.$toast.add({
            severity: "error",
            summary: "Campo invalido",
            detail: "As senhas não conferem.",
            life: 5000,
          });
          return;
        }
      }

      const cpfDigits = cpf.replace(/\D/g, "");
      const payload = {
        cpf: cpfDigits,
        name: name,
        cell_phone: cell_phone,
        email: email,
        password: password,
        password_confirmation: confirmPassword,
        customer_id: customer_id,
        token: token,
      };
      this.loading = true;
      axios
        .post(`/user/register`, payload)
        .then((response) => {
          this.formData.token = response.data.data.token;

          // if (response.status === 200 || response.status === 201) {

          // }

          if (response.data.action == "cadastro_finalizado") {
            this.$toast.add({
              severity: "success",
              summary: "Success",
              detail: "Cadastro criado com sucesso, faça o login",
              life: 3000,
            });
            setTimeout(() => {
              this.$router.push("/login");
            }, 3000);
            return false;
          }

          if (response.data.action == "validate-cell-phone") {
            this.fieldCellPhone = 1;
            this.formData.token = response.data.data.token;
            return false;
          }

          if (response.data.action == "validate-email") {
            this.fieldEmail = 1;
            this.formData.token = response.data.data.token;
            return false;
          }

          if (response.data.action == "create-user") {
            this.fieldEmail = 1;
            this.fieldCpf = 1;
            this.fieldCellPhone = 1;
            this.fieldName = 1;
            this.fieldPassword = 1;
            this.fieldPasswordComfirmation = 1;
            this.formData.token = response.data.data.token;
            return false;
          }

          if (response.data.action == "form_token_add_email") {
            this.fieldEmail = 1;
            this.fieldCpf = 1;
            this.fieldPassword = 1;
            this.fieldPasswordComfirmation = 1;
            this.formData.token = response.data.data.token;
            return false;
          }

          if (response.data.action == "form_token_email") {
            this.fieldCellPhone = 1;
            this.fieldEmail = 1;
            this.fieldCpf = 1;
            this.fieldPassword = 1;
            this.fieldPasswordComfirmation = 1;
            this.formData.token = response.data.data.token;
            return false;
          }

          if (response.data.action == "form_token") {
            this.$toast.add({
              severity: "success",
              summary: "Success",
              detail:
                "Localizmos o seu cadastro agora só precisa informar a senha!",
              life: 3000,
            });

            this.fieldEmail = 0;
            this.fieldCpf = 0;
            this.fieldCellPhone = 0;
            this.fieldName = 0;
            this.fieldPassword = 1;
            this.fieldPasswordComfirmation = 1;
            this.formData.token = response.data.data.token;

            return false;
          }

          if (response.data.action == "form_full") {
            if (response.data.data.cpf == "") {
              this.formData.cpf = cpfDigits;
            } else {
              this.formData.cpf = response.data.data.cpf;
            }

            this.formData.name = response.data.data.name;
            this.formData.email = response.data.data.email;
            this.formData.password = "";
            this.formData.confirmPassword = "";
            this.formData.cell_phone = response.data.data.cell_phone;
            this.formData.customer_id = response.data.data.customer_id;
            this.formData.token = response.data.data.token;

            this.fieldEmail = 1;
            this.fieldCpf = 1;
            this.fieldCellPhone = 1;
            this.fieldName = 1;
            this.fieldPassword = 1;
            this.fieldPasswordComfirmation = 1;
            return false;
          }
        })
        .catch((error) => {
          let error_message = error.response?.data?.errors;
          this.displayError.status = false;
          if (error.response?.status === 400) {
            if (
              error_message &&
              error_message.cpf &&
              error_message.cpf[0] === "The cpf has already been taken."
            ) {
              this.$toast.add({
                severity: "success",
                summary: "Success",
                detail:
                  "Já existe uma conta para o CPF informado, faça o login",
                life: 3000,
              });
              setTimeout(() => {
                this.$router.push("/login");
              }, 3000);
            } else {
              if (error.response?.data?.message == "Usuário já cadastrado") {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail:
                    "Já existe uma conta para o CPF e E-mail informado, faça o login",
                  life: 3000,
                });
                setTimeout(() => {
                  this.$router.push("/login");
                }, 3000);
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: error.response?.data?.message,
                  life: 3000,
                });
              }
            }
          }
          if (error.response?.status === 404) {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: error.response?.data?.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss">
.vti__input {
  background-color: transparent !important;
  color:#000;
}

.vti__input::placeholder {
  color: #828282 !important;
}

.vue-tel-input input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #fff inset !important;
  -webkit-text-fill-color: #212529 !important;
}
</style>

<style lang="css" scoped>
body {
  font-family: "Montserrat", sans-serif;
  margin: 0;
}

.main-login {
  background-image: url("~@/assets/background-login.png");
  /* background-image: url("~@/assets/bg-login.jpg"); */
  background-size: cover;
  background-position: center;
  padding: 40px 0;
  min-height: 100vh;
}

.rigth-login {
  padding-top: 40px;
  color: aliceblue;
}
h4 {
  font-size: 20px;
}
.separator {
  width: 100%;
  border: 1px solid #b9b9b9;
  margin: 10px 0;
}
.quote {
  display: flex;
  margin-left: 200px;
}
.quote > span {
  color: #d75413;
  font-size: 100px;
  font-weight: bold;
  margin: 0;
}
.quote > h4 {
  text-align: center;
  font-size: 24px;
  margin: 0;
}

.card-login {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #ffffff10;
  padding: 40px;
  border-radius: 10px;
  border: 2px solid #ffffff30;
  backdrop-filter: blur(10px);
  box-shadow: 0px 10px 40px #00000056;
}

.card-login > img {
  width: 30%;
  height: auto;
  margin-bottom: 30px;
}

.card-login > h3 {
  color: aliceblue;
  font-size: 22px;
  font-weight: 800;
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.card-login > h5 {
  color: aliceblue;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.textfield {
  width: 100%;
}
a {
  font-size: 14px;
}

.textfield > input {
  width: 100%;
  height: 38px;
  border: 1px solid #b9b9b9;
  border-radius: 5px;
  background: #ffffff;
  font-size: 12pt;
  box-shadow: 0px 10px 40px #00000056;
  outline: none;
  box-sizing: border-box;
}

label {
  color: aliceblue;
  font-size: 14px;
  margin-bottom: 5px;
}

.textfield > input::placeholder {
  color: gray;
}

.checkbox {
  color: aliceblue;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 10px;
}

.btn-login {
  width: 100%;
  height: 38px;
  margin-top: 30px;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  outline: none;
  font-weight: bold;
  letter-spacing: 2px;
  color: aliceblue;
  background: #d75413;
  cursor: pointer;
}

.cadastro {
  color: aliceblue;
  text-decoration: none;
}
.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #181d2f;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  opacity: 1;
  transition: opacity 0.5s ease-in-out; /* Transição suave da opacidade */
  pointer-events: all; /* Permite interação com o loading */
}

.loading-logo {
  width: 100px; /* Largura inicial da logo */
  height: auto;
  animation: pulse 1.5s infinite alternate; /* Animação de pulsação */
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2); /* Aumenta a logo em 20% */
  }
}

.content {
  display: none;
}

@media only screen and (max-width: 990px) {
  .main-login {
    background-image: url("~@/assets/bg-login.jpg");
  }
}
</style>
